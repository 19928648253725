// import { configureStore } from "@reduxjs/toolkit";
import categoryReducer from "./category";
import cartReducer from "./cart";
import dishReducer from "./dish";
import drawerReducer from "./drawer";
import createOrderReducer from "./createOrder";
import orderReducer from "./order";
import restaurantReducer from "./restaurant";
import locationsReducer from "./locations";
import editingOrderReducer from "./editingOrder";
import serviceReducer from "./service";
import tableReducer from "./table";
import zoneReducer from "./zone";
import channelReducer from "./channel";
import tenderReducer from "./tenders";
import duplicateOrderReducer from "./duplicateReceipt";
import zoneTableDetailReducer from "./zoneTable";
import shiftReportReducer from "./shift";
import appReducer from "./app";
import { configureStore, combineReducers } from "@reduxjs/toolkit";

// export const store = configureStore({
//   reducer: {
// cart: cartReducer,
// category: categoryReducer,
// dish: dishReducer,
// drawer: drawerReducer,
// createOrder: createOrderReducer,
// order: orderReducer,
// restaurant: restaurantReducer,
//   },
// });

const initialState = {
  cart: {
    cart: {},
    activeCart: null,
    checkoutOrder: null
  },
  // ... other initial state
};

const combinedReducer = combineReducers({
  cart: cartReducer,
  category: categoryReducer,
  createOrder: createOrderReducer,
  dish: dishReducer,
  drawer: drawerReducer,
  editOrder: editingOrderReducer,
  location: locationsReducer,
  order: orderReducer,
  restaurant: restaurantReducer,
  service: serviceReducer,
  table: tableReducer,
  zoneTableDetail: zoneTableDetailReducer,
  zone: zoneReducer,
  channel: channelReducer,
  tender: tenderReducer,
  duplicateOrder: duplicateOrderReducer,
  shiftReport: shiftReportReducer,
  app: appReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "restaurant/logoutRestaurant") {
    state = undefined;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});
