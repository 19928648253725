import React, { useLayoutEffect, useState } from "react";
import classes from "./ReportReceiptBuilder.module.scss";
import { ResturantService } from "services/ResturantService";
import logo from "assets/svg/defaultJalebi.svg";

const ReceiptItem = ({ name, qty, amount }) => (
  <tr>
    <td className={`${classes.ItemNameCell} !pt-3 capitalize`}>{name}</td>
    <td style={{ width: "30%" }} className="!text-center !pt-3">
      {qty}
    </td>
    <td style={{ width: "30%" }} className="!pt-3">
      {amount}
    </td>
  </tr>
);

const ReceiptSection = ({ title, items }) => (
  <>
    {items.length > 0 ? (
      <div className="receipt-section">
        <h2 className="text-[24px] mt-6 mb-3 uppercase">{title.split("_").join(" ")}</h2>
        <table className={classes.ReceiptTable}>
          <thead>
            <tr className="uppercase" style={{ borderBottom: "2px solid #c5c5c5" }}>
              <th className={classes.ItemNameCell}>Name</th>
              <th className="!text-center">Quantity</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {items?.map((item, index) => (
              <ReceiptItem key={index} {...item} />
            ))}
          </tbody>
        </table>
      </div>
    ) : null}
  </>
);

const ReportReceiptBuilder = ({ data }) => {
  const [smallRestaurantLogo, setSmallRestaurantLogo] = useState(null);

  useLayoutEffect(() => {
    ResturantService.getResturantInfo(
      (res) => {
        setSmallRestaurantLogo(res?.data?.data?.data?.smallimage);
      },
      (err) => {
        // console.log({ err });
      }
    );
  }, []);
  const receiptLogo = smallRestaurantLogo || logo;
  const {
    restaurant_name,
    outlet_name,
    outlet_address,
    time_of_report,
    vat,
    shift_close,
    shift_open,
    end_of_report,
    ...sections
  } = data;
  return (
    <div className={classes.Receipt}>
      <header>
        <img
          src={receiptLogo}
          alt="Company Logo"
          height={50}
          width={50}
          className="block mb-4 mx-auto z-50"
        />
        <h1>{restaurant_name || ""}</h1>
        <p>{outlet_name || ""}</p>
        <p>{outlet_address || ""}</p>
        <p>{time_of_report || ""}</p>
        <p>VAT: {vat || ""}</p>
        <p>Shift Open: {shift_open || ""}</p>
        <p>Shift Close: {shift_close || ""}</p>
      </header>
      <main>
        {Object.keys(sections).map((key) => (
          <ReceiptSection
            key={key}
            title={key.charAt(0).toUpperCase() + key.slice(1)}
            items={sections[key]}
          />
        ))}
      </main>
      <p style={{ marginTop: "25px" }}>*** {end_of_report} ***</p>
    </div>
  );
};

export default ReportReceiptBuilder;
