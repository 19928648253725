import Loader from "components/Loader/Loader";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import { React, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { cn } from "utillity/utils";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { addActiveEditTable } from "redux/zoneTable";
import { TableServices } from "services/TableServices";
import { deleteTable } from "redux/table";
import { statusClasses } from "../../../utillity/tableStatus"

const FloorTables = ({
    zoneDetailError,
    zoneDetailLoading,
    zoneTableDetail,
    onSelectTable,
    onSelectMergeTable,
    getTable = () => { },
    selectedTables,
    selectedState,
    onTableDeleted = () => { },
    clearSelection,
    activeZone
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [selectedMergedTable, setSelectedMergedTable] = useState(null);
    //   Calculating the total table length for the zone
    // const totalTable =
    //   vacant_table + reserved_table + occupied_table + disabled_table;
    let allActiveArray = [];
    // useMemo(() => {
    //   if (zoneTableDetail && zoneTableDetail?.name && !zoneDetailLoading) {
    //     allActiveArray = [
    //       ...zoneTableDetail?.vacant_table,
    //       ...zoneTableDetail?.occupied_table,
    //       ...zoneTableDetail?.reserved_table,
    //       ...zoneTableDetail?.disabled_table,
    //     ];
    //   }
    // }, [zoneTableDetail, zoneDetailLoading]);
    useEffect(() => {
        if (clearSelection) {
            setSelectedMergedTable(null);
        }
    }, [clearSelection]);

    if (zoneTableDetail && zoneTableDetail.name) {
        allActiveArray = [
            ...zoneTableDetail.vacant_table,
            ...zoneTableDetail.occupied_table,
            ...zoneTableDetail.reserved_table,
            ...zoneTableDetail.disabled_table,
           

        ];
    }
    const handleSelectTable = (table) => {
        if (selectedState === 'merge') {
            setSelectedMergedTable((prevSelected) =>
                prevSelected?.id === table.id ? null : table
            );
            onSelectMergeTable(table)
        }
    };

    const onTableClick = (table) => {
        if (selectedState === 'floor') {
            dispatch(addActiveEditTable(table));
            navigate("/floor/tables/update/" + table.table_number);
        }
        else {
            onSelectTable(table);
        }
    };
    const handleAction = (action, selectedMergeTable) => {
        if (action === 'unmerge') {
            try {
                const mergedTable = {
                    merged_table_id: selectedMergeTable.id,
                };
                TableServices.unmergeTable(
                    mergedTable,
                    (res) => {
                        toast.success("Tables have been successfully unmerged!", {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                        dispatch(deleteTable(selectedMergeTable.id));
                        if (onTableDeleted) onTableDeleted();
                    },
                    (error) => {
                        // Show the error message from the backend
                        const errorMessage = error.message || "Error unmerging table";
                        toast.error(errorMessage, {
                            position: toast.POSITION.BOTTOM_RIGHT,
                        });
                    }
                );
            } catch (error) {
                toast.error("Error unmerging tables. Please try again.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            }
        }
    };

    let mergedTables = allActiveArray.filter(x => x.merged_tables)
    mergedTables.sort((a, b) => {
        if (a.table_number < b.table_number) return -1;
        if (a.table_number > b.table_number) return 1;
        return 0;
    });
   
    allActiveArray = allActiveArray.filter(x => !x.merged_tables)

    return (
        <div className="w-full h-full px-[1rem] lg:px-[2rem] py-[1rem] overflow-y-auto h-[500px]">
            <div className="w-full ">
                {/* Loading view */}
                {zoneDetailLoading && !zoneDetailError && (
                    <div className="w-full h-full flex flex-col items-center justify-center">
                        <Loader />
                    </div>
                )}
                {/* Error view */}
                {!zoneDetailLoading && zoneDetailError && (
                    <div className="w-full h-full flex flex-col items-center justify-center">
                        <p className="text-2xl lg:text-2xl font-Inter font-medium text-red-300 text-center">
                            Error occurred while fetching your tables
                        </p>
                        <div className="mt-10 w-full flex flex-row items-center justify-center">
                            <PrimaryButton
                                text="Try Again"
                                onClicked={() => {
                                    getTable();
                                }}
                            />
                        </div>
                    </div>
                )}
                {/* Empty view */}
                {!zoneDetailError &&
                    !zoneDetailLoading &&
                    allActiveArray?.length === 0 && mergedTables?.length === 0 && (
                        <div className="w-full h-full flex flex-col items-center justify-center mt-40">
                            <p className="pt-5 text-2xl font-Inter font-medium text-textColor">
                                <span>No table available for this zone.</span>
                            </p>
                        </div>
                    )}
                {/* Tables view */}
                {!zoneDetailError &&
                    !zoneDetailLoading &&
                    allActiveArray?.length > 0 && (
                        <div className="w-full grid gap-4 lg:grid-cols-4 2xl:grid-cols-6 md:grid-cols-4">
                            {allActiveArray?.map((item, index) => (
                                <button
                                    key={index}
                                    onClick={() => onTableClick(item)}
                                    className={cn(
                                        "flex flex-col items-center bg-white justify-center px-[1rem] py-[2rem] xl:py-[3rem] rounded-[4px]",
                                        [
                                            item?.status === "available" && !selectedTables.some(t => t.id === item.id) &&
                                            "border-b-[2px] border-b-[#29CC6A]"],
                                        [
                                            item?.status === "disabled" &&
                                            "bg-transparent opacity-40",
                                        ],
                                        [
                                            item?.status === "occupied" && !selectedTables.some(t => t.id === item.id) &&
                                            "border-b-[2px] border-b-[#F3781F]",
                                        ],
                                        [selectedTables.some(t => t.id === item.id) ? 'bg-black' : '']
                                    )}
                                >
                                    <p className={cn("text-[1.2rem] lg:text-[1.5rem] font-bold text-gray-700",
                                        [selectedTables.some(t => t.id === item.id) ? 'font-bold text-white' : '']
                                    )}>
                                        {item.table_number}
                                    </p>
                                    <p className={`pt-2 text-[12px] font-medium ${selectedTables.some((t) => t.id === item.id) ? 'text-white' : ''}`}>
                                        {selectedTables.some((t) => t.id === item.id) ? "Selected" : `seats ${item.no_of_seats}`}
                                    </p>
                                </button>
                            ))}
                        </div>
                    )}
                {!zoneDetailError && !zoneDetailLoading && mergedTables.length > 0 && (
                    <div className="w-full ">
                        <div className="w-full h-full px-4 py-4">
                            {mergedTables.length > 0 ? (
                                mergedTables.map((table, index) => (
                                    <div
                                        key={index}
                                        onClick={() => handleSelectTable(table)}
                                        className={cn(
                                            "mb-8 p-4 w-full border border-gray-300 rounded-lg bg-white shadow-md -ml-3",

                                            [selectedMergedTable?.id === table.id && "border-[2px] border-black"]
                                        )}
                                    >
                                        {/* Header: Table Info */}
                                        <div className="flex items-center justify-between mb-4">
                                            <div>
                                                <h3 className="text-xl font-bold">
                                                    Table {table.table_number}
                                                </h3>
                                                <div className="flex space-x-2 text-sm">
                                                    <span className="px-3 py-1 bg-green-600 text-white rounded">
                                                        {table?.merged_tables?.length} Tables
                                                    </span>
                                                    {activeZone && (<span className="px-3 py-1 bg-black text-white rounded">
                                                        {table.primary_zone_name ? table.primary_zone_name : activeZone.name}
                                                    </span>)}
                                                    <span
                                                        className={cn(
                                                            "px-3 py-1 rounded capitalize",
                                                            statusClasses[table.status] || "bg-green-600 text-white" // Fallback for unexpected statuses
                                                        )}

                                                    >
                                                        {table.status}
                                                    </span>
                                                </div>
                                            </div>
                                            {/* Action Buttons */}
                                            <div className="flex space-x-2">
                                                {/* <button
                                                    className="px-3 py-1 bg-gray-200 hover:bg-gray-300 rounded"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleAction("rename", table);
                                                    }}
                                                >
                                                    Rename
                                                </button> */}
                                                <button
                                                    className="px-3 py-1 bg-gray-200 hover:bg-gray-300 rounded"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleAction("unmerge", table);
                                                    }}
                                                >
                                                    Unmerge
                                                </button>
                                                {/* <button
                          className="px-3 py-1 bg-gray-200 hover:bg-gray-300 rounded"
                          onClick={() => handleAction("split", table)}
                        >
                          Split
                        </button>
                        <button
                          className="px-3 py-1 bg-gray-200 hover:bg-gray-300 rounded"
                          onClick={() => handleAction("split-merge", table)}
                        >
                          Split & Merge
                        </button> */}
                                            </div>
                                        </div>

                                        {/* Table Cards */}
                                        <div className="grid grid-cols-8 gap-4">
                                            {table.merged_tables.map((t, i) => (
                                                <div
                                                    key={i}
                                                    className="border border-gray-400 p-4 text-center rounded-lg break-words"
                                                >
                                                    <p className="font-bold">{t.table_number}</p>
                                                    <p className="text-base text-gray-600 font-bold">Seats {t.no_of_seats}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <p>No merged tables available.</p>
                            )}
                        </div>

                    </div>
                )}
            </div>
        </div>
    );
};

export default FloorTables;
